<template>
  <div class="pb-12 mb-12 bbr-exercises--new-details">
    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <div class="mb-4 d-flex justify-space-between align-center">
          <h2 class="mr-auto">
            Exercise Details
          </h2>

          <v-btn
            class="mr-5 px-7"
            color="primary"
            :loading="form.$busy"
            @click="saveForLater"
            text
          >
            <span v-if="!form.completed">Save & Finish Later</span>
            <span v-else>Save & Close</span>
          </v-btn>

          <v-btn
            class="px-12 bg-primary-gradient primary next-button"
            :loading="this.form.$busy"
            @click="saveAndNext"
            depressed
            text
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12" md="10" lg="8" xl="7">
        <exercise-details-form :form-data="form" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ExerciseDetailsForm from '@/components/forms/exercises/ExerciseDetailsForm'
import ExerciseMixin from '../mixins/ExerciseMixin'
import Event from '@/services/eventBus'

import { mdiPlus, mdiArrowRight } from '@mdi/js'

export default {
  name: 'ExerciseDetails',

  mixins: [ExerciseMixin],

  components: {
    ExerciseDetailsForm,
  },

  data() {
    return {
      icons: {
        add: mdiPlus,
        next: mdiArrowRight,
      },
    }
  },

  methods: {
    async saveForLater() {
      if ((await this.save()).error) return

      this.redirectToListView()
    },

    async saveAndNext() {
      if ((await this.save()).error) return

      Event.$emit('draft-updated')

      this.redirectIf(this.isCompleted, 'exercise.instructions') ||
        this.redirectIf(!this.isCompleted, 'new.exercise.instructions')
    },
  },
}
</script>
